import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

// 公共路由
export const constantRoutes = [
  {
    path: '',
    redirect: 'identificationPhoto/carousel',
  },
  {//公共按钮
    path: '/menu',
    component: (resolve) => require(['@/views/menu'], resolve),
    
  },
  /*朵朵证件照开始*/
  {
    path: '/identificationPhoto',
    component: (resolve) => require(['@/views/identificationPhoto/index'], resolve),
  },
   {//联系我们
    path: '/identificationPhoto/contactUs',
    component: (resolve) => require(['@/views/identificationPhoto/contactUs/index'], resolve),
  },
  {//证件照攻略
    path: '/identificationPhoto/strategy',
    component: (resolve) => require(['@/views/identificationPhoto/strategy/index'], resolve),
    
  },
  {//证件照服务条款
    path: '/identificationPhoto/termsOfService',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/index'], resolve),
  },
  {//隐私政策
    path: '/identificationPhoto/privacyPolicy',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/privacyPolicy'], resolve),
  },
  {//用户协议
    path: '/identificationPhoto/userAgreement',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/userAgreement'], resolve),
  },
  {//个人信息收集清单
    path: '/identificationPhoto/informationCollection',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/informationCollection'], resolve),
  },
  {//第三方信息共享清单
    path: '/identificationPhoto/sdkList',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/sdkList'], resolve),
  },
  {//系统权限申请与使用清单
    path: '/identificationPhoto/permissionList',
    component: (resolve) => require(['@/views/identificationPhoto/termsOfService/permissionList'], resolve),
  },
  {//VIP订阅须知
    path: '/identificationPhoto/vipSubscriptionNotes',
    component: (resolve) => require(['@/views/identificationPhoto/vipSubscriptionNotes/index'], resolve),
  },
  {//轮播图
    path: '/identificationPhoto/carousel',
    component: (resolve) => require(['@/views/identificationPhoto/carousel/index'], resolve),
  },
  {//介绍视频
    path: '/identificationPhoto/video',
    component: (resolve) => require(['@/views/identificationPhoto/video/index'], resolve),
  },
  /*朵朵证件照结束*/

  
  /*朵朵天气开始*/
  {
    path: '/weather',
    component: (resolve) => require(['@/views/weather/index'], resolve),
  },
  {//隐私政策
    path: '/weather/privacyPolicy',
    component: (resolve) => require(['@/views/weather/privacyPolicy'], resolve),
  },
  {//用户协议
    path: '/weather/userAgreement',
    component: (resolve) => require(['@/views/weather/userAgreement'], resolve),
  },
  {//软件介绍和愿景
    path: '/weather/softwareIntroduction',
    component: (resolve) => require(['@/views/weather/softwareIntroduction'], resolve),
  },
  /*朵朵天气结束*/

]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
